<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text class="pb-6">
        <p class="text-center text-xl font-weight-semibold black--text mb-2">
          {{ t('create_account.create_account') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('create_account.enter_info') }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="createSubmit"
        >
          <div class="d-flex justify-space-between">
            <v-text-field
              v-model="registerData.firstName"
              outlined
              rounded
              :placeholder="t('create_account.first_name')"
              :rules="[validators.required]"
              class="mb-1 pr-2"
            />

            <v-text-field
              v-model="registerData.lastName"
              outlined
              rounded
              :placeholder="t('create_account.last_name')"
              :rules="[validators.required]"
              class="mb-1 pl-2"
            />
          </div>

          <div
            class="d-flex justify-space-between mb-1"
          >
            <v-autocomplete
              v-model="registerData.phoneCode"
              class="pr-1 w-code"
              outlined
              rounded
              :items="phoneCodeOptions"
              item-text="text"
              item-value="value"
              :append-icon="loginData.type === 'PHONE_NUMBER' ? null : icons.mdiMenuDown"
              :readonly="loginData.type === 'PHONE_NUMBER'"
              :rules="[validators.required]"
              @change="() => registerData.phoneNumber = null"
            >
              <template v-slot:selection="{ item }">
                <img
                  :src="item.image"
                  :alt="item.value"
                  width="15"
                  height="auto"
                  class="mr-2"
                >
                {{ item.text }}
              </template>
              <template v-slot:item="{ item }">
                <img
                  :src="item.image"
                  :alt="item.value"
                  width="20"
                  height="auto"
                  class="mr-2"
                >
                {{ item.text }}
              </template>
            </v-autocomplete>

            <v-text-field
              ref="phoneInput"
              v-model="registerData.phoneNumber"
              class="pl-1"
              :label="t('users.phone')"
              outlined
              rounded
              :disabled="!registerData.phoneCode"
              :readonly="loginData.type === 'PHONE_NUMBER'"
              :rules="[validators.required, validatePhone]"
              :clearable="loginData.type !== 'PHONE_NUMBER'"
            />
          </div>

          <v-text-field
            v-model="registerData.email"
            outlined
            rounded
            :placeholder="t('create_account.email_address')"
            :readonly="loginData.type === 'EMAIL'"
            :rules="[validators.required]"
            class="mb-1"
          />

          <v-text-field
            v-model="registerData.password"
            outlined
            rounded
            :placeholder="t('create_account.password')"
            :rules="[validators.required, validators.minLengthValidator(loginData.password, 6)]"
            class="mb-1"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
            @click:append="isPasswordVisible = !isPasswordVisible"
          />

          <v-checkbox
            v-model="acceptedTerms"
            hide-details=""
            :rules="[validators.required]"
            class="mb-3"
          >
            <template v-slot:label>
              <div>
                {{ t('create_account.accept_terms') }}
                <v-tooltip location="bottom">
                  <template v-slot:activator="{ props }">
                    <a
                      style="color: #FF3232"
                      target="_blank"
                      href="https://www.fanaty.com/terms"
                      v-bind="props"
                      @click.stop
                    >
                      {{ t('create_account.terms') }}
                    </a>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>

          <v-btn
            block
            color="primary"
            type="submit"
            rounded
            large
            :disabled="!isFormValid"
            class="mt-6 text-capitalize"
          >
            {{ t('tooltip.continue') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiEyeOutline, mdiEyeOffOutline, mdiMenuDown } from '@mdi/js'
import { ref, onMounted, getCurrentInstance, computed } from '@vue/composition-api'
import { required, emailValidator, minLengthValidator } from '@core/utils/validation'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import usePhone from '@core/utils/usePhone'
import useCountry from '@core/utils/useCountry'
import themeConfig from '@themeConfig'
import { useRouter } from '@core/utils'
import { registerUser } from '@api'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'
import parsePhoneNumber from 'libphonenumber-js'

export default {
  setup() {
    const { router } = useRouter()
    const { t } = useUtils()
    const { encrypt } = useCryptoJs()
    const { logData, currentLogData, updateLogData } = useLogData()
    const { phoneCodeOptions, initPhoneConfig } = usePhone()
    const { getCountryCode } = useCountry()
    const vm = getCurrentInstance().proxy

    const form = ref(null)
    const loginData = ref({})
    const registerData = ref({
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      validateData: 'YES',
      phoneCode: '',
      phoneNumber: '',
      fullPhone: null,
    })
    const initCode = ref(null)
    const errorMessages = ref([])
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)
    const acceptedTerms = ref(false)

    const isFormValid = computed(() => {
      if (
        registerData.value.firstName
        && registerData.value.lastName
        && registerData.value.fullPhone
        && registerData.value.email
        && registerData.value.password
        && minLengthValidator(registerData.value.password, 6) === true
        && acceptedTerms.value
      ) return true

      return false
    })

    const createSubmit = async () => {
      if (form.value.validate() && acceptedTerms.value) {
        const bodyRegister = {
          first_name: registerData.value.firstName,
          last_name: registerData.value.lastName,
          phone_number: registerData.value.fullPhone,
          email: registerData.value.email,
          password: registerData.value.password,
          validate_data: 'YES',
          type: loginData.value.type,
        }
        const encryptBodyRegister = encrypt(bodyRegister)
        const resp = await registerUser(encryptBodyRegister)
        if (resp.ok) {
          const body = {
            code: loginData.value.type === 'EMAIL' ? loginData.value.code : loginData.value.fullPhone,
            type: loginData.value.type,
            join_game: loginData.value.joinGame,
            match_code: loginData.value.matchCode,
            login_route: 'LOGIN_PAGE',
            password: registerData.value.password,
          }
          const encryptBody = encrypt(body)

          const userAbility = [
            {
              action: 'manage',
              subject: 'all',
            },
          ]
          vm.$ability.update(userAbility)

          const payload = {
            data: encryptBody,
            redirect: logData.value.redirect,
            facility: logData.value.facility,
            field: logData.value.field,
            date: logData.value.date,
            time: logData.value.time,
            slot: logData.value.slot,
            pay: logData.value.pay,
            reservationCode: logData.value.reservationCode,
            showPay: logData.value.showPay,
            team: logData.value.team,
            payType: logData.value.payType,
          }

          // remove team
          store.dispatch('auth/loginUserAction', payload)
        } else if (resp.message.code === 'U019') {
          logData.value.avatar = resp.data.avatar
          logData.value.fullname = resp.data.fullname
          logData.value.info = resp.data.info
          logData.value.typeIsThisYou = resp.data.type
          logData.value.username = resp.data.its_you_username
          logData.value.register = registerData.value
          updateLogData(logData.value)
          router.push({ name: 'auth-is-this-you' })
        }
      }
    }

    const validatePhone = e => {
      if (registerData.value.phoneCode && e) {
        const phoneNumberJS = parsePhoneNumber(e, registerData.value.phoneCode)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            registerData.value.fullPhone = phoneNumberJS.number

            return true
          }
        }
      }
      registerData.value.fullPhone = ''

      return 'Invalid number'
    }

    onMounted(async () => {
      Object.assign(logData.value, currentLogData.value)
      logData.value.loginRoute = 'LOGIN_PAGE'
      loginData.value = logData.value

      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }

      if (currentLogData.value) {
        if (loginData.value.type === 'EMAIL') {
          registerData.value.email = loginData.value.code
          registerData.value.phoneCode = loginData.value.phoneCode || initCode.value
        } else {
          registerData.value.phoneNumber = loginData.value.phoneNumber
          registerData.value.phoneCode = loginData.value.phoneCode
          registerData.value.fullPhone = loginData.value.fullPhone
        }
      }
    })

    return {
      form,
      loginData,
      registerData,
      errorMessages,
      isPasswordVisible,
      isPasswordConfirmVisible,
      isFormValid,
      acceptedTerms,
      phoneCodeOptions,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      createSubmit,
      validatePhone,
      initPhoneConfig,

      t,

      validators: {
        required,
        emailValidator,
        minLengthValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
